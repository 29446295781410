<style scoped>
.newHomePageNew{
    background: #F2F2F2;
    min-height: 100vh;
}
.wrapCon{
    width: 83%;
    margin: 0 auto;
    padding-bottom: 1px;
}
/* 每一块的标题样式 */
.titleCommon{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titleCommon .title {
    font-size: 18px;
    color: #323233;
    text-align: left;
    font-weight: 400;
}
.titleCommon .title span{
    font-size: 14px;
    color: #4D4D4D;
    margin-left: 18px;
    font-weight: 400;
}
.titleCommon .subTitle{
    font-size: 14px;
    color: #9F9F9F;
    margin-top: 4px;
}
.titleCommon .differTitle{
  text-align: left;
  max-width: 80%;
}

/* 待办消息 */
.noticeArea,.productArea{
    background: #fff;
    padding: 20px;
    margin-top:8px;
    padding-bottom: 0;
}
.messageList{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #323233;
    margin-top: 26px;
    margin-bottom:6px;
}
.messageList .grid{
    flex: 1;
}
.messageList .grid img{
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.messageList .grid p{
    margin-top: 16px;
}
/* 应用中心 */
.appBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 22px;
    margin: 10px 0;
  }
.appBox .oneApp {
    width: 240px;
    height: 80px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
    padding: 0 16px;
    margin-right: 22px;
    position:relative;
    margin-bottom: 20px;
}
.appBox .oneApp:hover {
    transition: all 0.5s;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
}
.appBox .oneApp img {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
}
.appBox .oneApp .p1 {
    font-size: 1.1rem;
    margin-bottom: 4px;
    color: #323232;
    font-weight: 400;
}
.appBox .oneApp .p2 {
    font-size: 0.8rem;
    color: #6E6E6E;

}
.appBox .oneApp .diffImg{
  border-radius: 50%;
}
.appBox .oneApp .dot{
  border-radius: 50%;
  background: red;
  display: inline-block;
  width: 6px;
  height: 6px;
  position:absolute;
  left: 60px;
}
/* 图表 */
.chartArea{
    margin-top: 8px;
}
  .chartArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    color: #323233;
  }

  .chartArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #323233;
    font-size: 14px;
    box-shadow: 2px 0 0.5rem 1px rgba(0, 0, 0, 0.1);
  }

  .chartArea /deep/ .el-tabs--border-card {
    border: none;
    box-shadow: 0 0 0 0;
  }

  .chartArea /deep/ .el-tabs--border-card>.el-tabs__header{
    background: #F2F2F2;
    border: none;
  }
  .chartArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item{
      border: none;
      height: 44px;
      width: 120px;
      line-height: 44px;
      color: #323233;
  }
  .chartArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    border: none;
  }
  .chartArea /deep/ .el-tabs--border-card>.el-tabs__content{
      padding: 0;
  }

  .chartBox{
      background: #fff;
      padding: 20px;
      margin-bottom: 8px;
  }
  .chartBox .grids{
      /* padding: 0 80px; */
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      /* justify-content: space-between; */
      margin-top: 40px;
      margin-left: 60px;
      margin-right: 60px;
  }
  .chartBox .grids .grid{
      width: 180px;
      /* flex-basis: 240px; */
      height: 120px;
      padding: 20px 20px;
      box-sizing: border-box;
      box-shadow: 0px 8px 12px rgba(72, 72, 72, 0.16);
      border-radius: 8px;
      margin-bottom: 30px;
      margin-right: 70px;
  }
  .chartBox .grids .grid .t1{
      font-size: 14px;
  }
  .chartBox .grids .grid .t2{
      font-size: 24px;
      margin: 6px 0;
      font-weight: bold;
  }
  .chartBox .grids .grid div{
      border-radius: 20px;
      font-size: 14px;
      background:#d8e2fe; 
      display: flex;
      align-items: center;
      justify-content: center;
  } 
  .chartCanvas{
      padding: 31px 0 0 31px;
      height: 520px;
  }
  .rateIcon{
    width: 10px;
    margin-left: 10px;
  }
  .newTitle{
    text-align: left;
    font-size: 18px;
    color: #323233;
    margin-top: 20px;
    padding-left: 16px;
    font-weight: 400;
  }
</style>
<style scoped>
  .chartBox /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-size: 14px;
    color: #4D4D4D;
    font-family: 'PingFang SC';
    padding-right: 30px;
    background: none;
    line-height: 30px;
    height: 30px;
  }
  .chartBox /deep/ .el-input__icon{
    line-height: 30px;
  }

  .chartBox /deep/ .el-select-dropdown__item.selected {
    color: #4fa1a4;
  }

  .chartBox /deep/ .el-select .el-input .el-select__caret {
    height: auto;
    font-size: 14px;
    color: #323232;
  }
  .chartBox /deep/ .el-select{
    margin-right: 20px;
  }
</style>
<template>
  <div class="newHomePageNew">

    <UserHeader :companyOption='companyOption' @changeCompany="changeCompany"></UserHeader>

    <div class="wrapCon">
      <!-- 消息 -->
      <div class="noticeArea">
        <div class="titleCommon">
          <div class="title">待办信息</div>
        </div>
        <div class="appBox">
          <div class="oneApp" v-for="(item, index) in messageList" :key="index" @click="messageJump(item)">
            <img class="diffImg" :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
            <div>
              <span v-if="item.msgValue" class="dot">&nbsp;</span>
              <p class="p1">{{ item.serviceName }}</p>
              <p class="p2" v-if="item.msgValue">{{item.msgValue}}{{item.messageUnit}}</p>
              <p class="p2" v-else>0条</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 应用中心 -->
      <div class="productArea">
        <div class="titleCommon">
          <div class="title">应用中心</div>
        </div>
        <div class="appBox">
          <div class="oneApp" v-for="(item, index) in productsMenu" :key="index" @click="platformJump(item)">
            <img :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
            <div>
              <p class="p1">{{ item.productName }}</p>
              <p class="p2">{{item.productDesc}}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 图表内容 -->
      <div class="chartArea">
        <el-tabs type="border-card" v-model="activeTab" @tab-click="changeTimeTab">
          <el-tab-pane label="上月" name="lastMonth" :lazy="true"></el-tab-pane>
          <el-tab-pane label="本月" name="currentMonth" :lazy="true"></el-tab-pane>
          <el-tab-pane label="近一年" name="currentYear" :lazy="true"></el-tab-pane>
        </el-tabs>
        <div class="chartBox">
          <div class="titleCommon">
            <div class="title">基础信息<span>数据范围：{{newFormTime.startTime |filterTime}} ~ {{newFormTime.endTime | filterTime}}</span></div>
          </div>
          <div class="grids">
            <div class="grid">
              <p class="t1">服务商数/家<el-tooltip class="item" effect="dark" content="承担数据采集和品牌推广的公司" placement="right-start"><i class="el-icon-question"></i></el-tooltip>
              </p>
              <p class="t2">{{baseInfo.serviceCount | filterData}}</p>
              <div>
                环比 {{baseInfo.serviceCountRate | filterData}}%
                <img class="rateIcon" v-if="baseInfo.serviceCountRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="baseInfo.serviceCountRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">业务线</p>
              <p class="t2" v-if="businessOption.length" :style="businessOption.length>1?'font-size:26px;margin-top:16px;':'margin-top:16px;'">
                <span v-for="(item,index) in businessOption" :key="index">{{index!=0?'、':''}}{{item.label}}</span>
              </p>
              <p class="t2" v-else style="margin-top:16px;">无</p>
            </div>
            <div class="grid">
              <p class="t1">在职人数</p>
              <p class="t2" v-if="activeTab == 'lastMonth'">{{baseInfo.noSelfStafflast | filterData}}</p>
              <p class="t2" v-else>{{baseInfo.noSelfStaffCount | filterData}}</p>
              <div v-if="activeTab == 'lastMonth'">
                环比 {{baseInfo.noSelfStafflastRate | filterData}}%
                <img class="rateIcon" v-if="baseInfo.noSelfStafflastRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="baseInfo.noSelfStafflastRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
              <div v-else>
                环比 {{baseInfo.noSelfStaffRate | filterData}}%
                <img class="rateIcon" v-if="baseInfo.noSelfStaffRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="baseInfo.noSelfStaffRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">发包任务数/个</p>
              <p class="t2">{{baseInfo.taskCount | filterData}}</p>
              <div style="background:#d2f1e9;">环比 {{baseInfo.taskCountRate | filterData}}%
                <img class="rateIcon" v-if="baseInfo.taskCountRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="baseInfo.taskCountRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">完成率</p>
              <p class="t2" style="margin-top:16px;">{{baseInfo.finishCountRate | filterData}}%</p>
            </div>
            <div class="grid">
              <p class="t1">投入人数</p>
              <p class="t2">{{baseInfo.noSelfUesStaffCount | filterData}}</p>
              <div style="background:#d2f1e9;">环比 {{baseInfo.noSelfUesStaffCountRate | filterData}}%
                <img class="rateIcon" v-if="baseInfo.noSelfUesStaffCountRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="baseInfo.noSelfUesStaffCountRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
          </div>
          <div class="chartCanvas">
            <div class="titleCommon">
              <div class="left">
                <div class="title">{{baseInfoSelectIndex | filterTitle(baseInfoOption)}}月趋势图</div>
                <div class="subTitle" v-if="baseInfoSelectIndex == '0' || baseInfoSelectIndex == '1'">说明：查看在职人数月数据以及环比趋势变化，在职人数=上个月在职人数+本月入职人数-本月离职人数。</div>
                <div class="subTitle differTitle" v-else>说明：该报表主要是查看投入人数月数据以及环比趋势变化，投入人数为参与业务数据的人员数量，包含离职的人数。例如某人在该月份有做任务，做了一段时间后在该月离职了，投入人数时也计算了这个人。</div>
              </div>
              <div class="rigth">
                <el-select v-model="baseInfoSelectIndex" placeholder="请选择" style="width:150px;" @change="getBaseInfoChart">
                  <el-option v-for="item in baseInfoOption" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div id="firstChart" ref="firstChart" style="width: 100%; height:450px;padding-right:30px;box-sizing:border-box; "></div>
          </div>
        </div>

        <div class="chartBox">
          <div class="titleCommon">
            <div class="title">数据采集<span>数据范围：{{newFormTime.startTime |filterTime}} ~ {{newFormTime.endTime | filterTime}}</span></div>
            <div class="rigth">
              <el-select v-model="businessSelectIndex" placeholder="请选择" style="width:100px;" @change="changeBusinessSelect">
                <el-option v-for="item in businessOption" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- <p class="newTitle">报货情况</p>
          <div class="grids" v-if="pageType=='OTC'">
            <div class="grid">
              <p class="t1">涉及产品数</p>
              <p class="t2">{{dataAcquisition.productNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.productNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.productNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.productNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">覆盖连锁药店数/家</p>
              <p class="t2">{{dataAcquisition.chainPharmacyNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.chainPharmacyNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.chainPharmacyNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.chainPharmacyNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">覆盖第三终端数/家</p>
              <p class="t2">{{dataAcquisition.terminalNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.terminalNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.terminalNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.terminalNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
          </div>
          <div class="grids" v-else>
            <div class="grid">
              <p class="t1">涉及产品数</p>
              <p class="t2">{{dataAcquisition.productNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.productNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.productNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.productNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">覆盖等级医院数/家</p>
              <p class="t2">{{dataAcquisition.hospitalNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.hospitalNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.hospitalNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.hospitalNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">覆盖院边店数/家</p>
              <p class="t2">{{dataAcquisition.pharmacyNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.pharmacyNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.pharmacyNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.pharmacyNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">覆盖第三终端数/家</p>
              <p class="t2">{{dataAcquisition.terminalNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.terminalNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.terminalNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.terminalNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
          </div>
          <div class="chartCanvas">
            <div class="titleCommon">
              <div class="left">
                <div class="title">{{dataAcquisitionSelectIndex | filterTitle(dataAcquisitionOption)}}月趋势图</div>
                <div class="subTitle" v-if="dataAcquisitionSelectIndex == '0'">说明：每个月覆盖的等级医院终端数以及月环比变化，月覆盖的等级医院终端数为该月有报货数据的等级医院数量。</div>
                <div class="subTitle" v-if="dataAcquisitionSelectIndex == '1'">说明：每个月覆盖的院边店终端数以及月环比变化，月覆盖的院边店终端数为该月有报货数据的院边店数量。</div>
                <div class="subTitle" v-if="dataAcquisitionSelectIndex == '2'">说明：每个月覆盖的第三终端数以及月环比变化，月覆盖的第三终端数为该月有报货数据的第三终端数量。</div>
                <div class="subTitle" v-if="dataAcquisitionSelectIndex == '3'">说明：每个月覆盖的连锁药店终端数以及月环比变化，月覆盖的连锁药店终端数为该月有报货数据的连锁药店数量。</div>
              </div>
              <div class="rigth">
                <el-select v-model="dataAcquisitionSelectIndex" placeholder="请选择" style="width:140px;" @change="getDataCollectionChart">
                  <el-option v-for="item in dataAcquisitionOption" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div id="secondChart" ref="secondChart" style="width: 100%; height:450px;padding-right:30px;box-sizing:border-box;  "></div>
          </div> -->

          <p class="newTitle">库存情况</p>
          <!-- 库存情况 -->
          <div class="grids">
            <div class="grid">
              <p class="t1">涉及产品数</p>
              <p class="t2">{{dataAcquisition.productNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.productNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.productNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.productNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">覆盖医院数/家</p>
              <p class="t2">{{dataAcquisition.hospitalNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.hospitalNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.hospitalNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.hospitalNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">覆盖渠道数/家</p>
              <p class="t2">{{dataAcquisition.companyNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.companyNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.companyNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.companyNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">月末库存数/盒</p>
              <p class="t2">{{dataAcquisition.stockNumber | filterData}}</p>
              <div>环比 {{dataAcquisition.stockNumberRate | filterData}}%
                <img class="rateIcon" v-if="dataAcquisition.stockNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="dataAcquisition.stockNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
          </div>
          <div class="chartCanvas">
            <div class="titleCommon">
              <div class="left">
                <div class="title">库存情况月趋势图</div>
                <div class="subTitle">说明：每个月月末库存数及环比趋势变化，同时可以通过筛选产品，查看不同产品的库存变化情况。</div>
              </div>
              <div class="rigth">
                <el-select v-model="goodsSelect" placeholder="请选择" style="width:140px;" @change="getStockChart">
                  <el-option v-for="(item,index) in goodsOption" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div id="furthChart" ref="furthChart" style="width: 100%; height:450px;padding-right:30px;box-sizing:border-box;  "></div>
          </div>
        </div>

        <div class="chartBox">
          <div class="titleCommon">
            <div class="title">市场推广<span>数据范围：{{newFormTime.startTime |filterTime}} ~ {{newFormTime.endTime | filterTime}}</span></div>
          </div>
          <div class="grids" v-if="pageType == 'OTC'">
            <div class="grid">
              <p class="t1">连锁药店调研数/家</p>
              <p class="t2">{{marketData.chainPharmacyNumber | filterData}}</p>
              <div>环比 {{marketData.chainPharmacyNumberRate | filterData}}%
                <img class="rateIcon" v-if="marketData.chainPharmacyNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="marketData.chainPharmacyNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">第三终端数/家</p>
              <p class="t2">{{marketData.terminalExamineNumber | filterData}}</p>
              <div>环比 {{marketData.terminalExamineNumberRate | filterData}}%
                <img class="rateIcon" v-if="marketData.terminalExamineNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="marketData.terminalExamineNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">渠道调研数/家</p>
              <p class="t2">{{marketData.channelNumber | filterData}}</p>
              <div>环比 {{marketData.channelNumberRate | filterData}}%
                <img class="rateIcon" v-if="marketData.channelNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="marketData.channelNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
          </div>
          <!-- v-if="pageType == 'RX'" -->
          <div class="grids" v-else>
            <div class="grid">
              <p class="t1">医院开发数/家</p>
              <p class="t2">{{marketData.hospitalNumber | filterData}}</p>
              <div>环比 {{marketData.hospitalNumberRate | filterData}}%
                <img class="rateIcon" v-if="marketData.hospitalNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="marketData.hospitalNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">医生调研数/次</p>
              <p class="t2">{{marketData.doctorNumber | filterData}}</p>
              <div>环比 {{marketData.doctorNumberRate | filterData}}%
                <img class="rateIcon" v-if="marketData.doctorNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="marketData.doctorNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
            <div class="grid">
              <p class="t1">患者调研数/次</p>
              <p class="t2">{{marketData.patientNumber | filterData}}</p>
              <div>环比 {{marketData.patientNumberRate | filterData}}%
                <img class="rateIcon" v-if="marketData.patientNumberRate>0" src="../../assets/personalPage/up.png" alt="">
                <img class="rateIcon" v-if="marketData.patientNumberRate<0" src="../../assets/personalPage/down.png" alt="">
              </div>
            </div>
          </div>
          <div class="chartCanvas">
            <div class="titleCommon">
              <div class="left">
                <div class="title">{{marketResearchSelectIndex | filterTitle(marketResearchOption)}}月趋势图</div>
                <div class="subTitle" v-if="marketResearchSelectIndex == '0'">说明：该表展示的是每个月开发的医院数据以及月环比变化，月医院开发数为本月为第一次报货的医院数量。</div>
                <div class="subTitle" v-if="marketResearchSelectIndex == '1'">说明：该表展示的是每个月医院调研次数以及月环比变化，月医院调研次数为本月拜访医院的次数。</div>
                <div class="subTitle" v-if="marketResearchSelectIndex == '2'">说明：该表展示的是每个月专家调研次数以及月环比变化，月专家调研次数为本月拜访专家的次数。</div>
                <div class="subTitle" v-if="marketResearchSelectIndex == '3'">说明：该表展示的是每个月患者调研次数以及月环比变化，月患者调研次数为本月拜访患者的次数。</div>
                <div class="subTitle" v-if="marketResearchSelectIndex == '4'">说明：该表展示的是每个月连锁药店调研次数以及月环比变化，月连锁药店调研次数为本月调研连锁药店的次数。</div>
                <div class="subTitle" v-if="marketResearchSelectIndex == '5'">说明：该表展示的是每个月第三终端调研次数以及月环比变化，月第三终端调研次数为本月调研第三终端的次数。</div>
                <div class="subTitle" v-if="marketResearchSelectIndex == '6'">说明：该表展示的是每个月渠道调研次数以及月环比变化，月渠道调研次数为本月采集渠道库存的次数。</div>
              </div>
              <div class="rigth">
                <el-select v-model="marketResearchSelectIndex" placeholder="请选择" style="width:140px;" @change="getMarketingChart">
                  <el-option v-for="item in marketResearchOption" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div id="thirdChart" ref="thirdChart" style="width: 100%; height:450px;padding-right:30px;box-sizing:border-box;  "></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
  import UserHeader from '@/components/UserHeader.vue';
  import toOther from './mixins/toOther';
  import newEcharts from 'echarts';
  import moment from 'moment'
  import { Loading } from 'element-ui';
  export default {
    name: 'SZ_homePageNew',
    components: {
      UserHeader
    },
    data() {
      return {
        defaultPng: require('../../assets/personalPage/icon3.png'),
        currentTenantGuid: null,
        currentStaffGuid: null,
        companyOption: [],
        organisationName: '--',
        positionName: '--',
        messageList: [],
        productsMenu: [],

        activeTab: 'currentMonth', //上月 lastMonth,本月 currentMonth,本年 currentYear   
        formData: {
          tenantGuid: "",
          startTime: "",
          endTime: "",
          startTimeLast: "",
          endTimeLast: "",
          accountMonth: "",
          upAccountMonth: '', //上月核算月
        },
        newFormTime: {
          startTime: "",
          endTime: "",
          startTimeLast: "",
          endTimeLast: "",
        },
        baseSearch: {},
        pageType: '',
        baseInfo: {}, //基本信息模块
        baseInfoSelectIndex: '1',
        baseInfoOption: [
          {
            label: '在职人数',
            value: '0'
          },
          {
            label: '投入人数',
            value: '1'
          }
        ],
        businessSelectIndex: null,
        businessOption: [], //业务单元下拉
        dataAcquisition: {}, //数据采集模块
        dataAcquisitionSelectIndex: '',
        dataAcquisitionOption: [  // rx
          {
            label: '覆盖等级医院数',
            value: '0'
          },
          {
            label: '覆盖院边店数',
            value: '1'
          },
          {
            label: '覆盖第三终端数',
            value: '2'
          },
        ],
        goodsSelect: '全部品种',
        goodsOption: [],//库存情况下拉
        marketData: {}, //市场推广模块
        marketResearchSelectIndex: '',
        marketResearchOption: [
          {
            label: '医院开发数',
            value: '0'
          },
          {
            label: '医生调研数',
            value: '1'
          },
          {
            label: '患者调研数',
            value: '3'
          },
        ],
        loadingInstance:null
      }
    },
    mixins: [toOther],
    filters: {
      filterTime(value) {
        if (value) {
          return moment(value).format('YYYY年MM月DD日')
        } else {
          return ''
        }
      },
      filterTitle(value, option) {
        let str = ''
        if (value) {
          option.forEach(item => {
            if (item.value == value) {
              str = item.label
            }
          });
        }
        return str
      },
      filterData(value) {
        return value ? value : 0
      }
    },
    watch: {
      pageType(newVal) {
        if (newVal) {
          if (newVal == 'OTC' || newVal == 'otc') {
            this.dataAcquisitionOption = [
              {
                label: '覆盖连锁药店数',
                value: '3'
              },
              {
                label: '覆盖第三终端数',
                value: '2'
              },
            ]
            this.marketResearchOption = [
              {
                label: '连锁药店调研数',
                value: '4'
              },
              {
                label: '第三终端调研数',
                value: '5'
              },
              {
                label: '渠道调研数',
                value: '6'
              },
            ]
          }
          if (newVal == 'RX' || newVal == 'rx') {
            this.dataAcquisitionOption = [
              {
                label: '覆盖等级医院数',
                value: '0'
              },
              {
                label: '覆盖院边店数',
                value: '1'
              },
              {
                label: '覆盖第三终端数',
                value: '2'
              },
            ]
            this.marketResearchOption = [
              {
                label: '医院开发数',
                value: '0'
              },
              {
                label: '医生调研数',
                value: '1'
              },
              {
                label: '患者调研数',
                value: '3'
              },
            ]
          }
          this.dataAcquisitionSelectIndex = this.dataAcquisitionOption[0].value
          this.marketResearchSelectIndex = this.marketResearchOption[0].value
        }
      },
      'formData.endTimeLast'(newVal, oldVal) {
        if (this.formData.startTime && this.formData.endTime && this.formData.startTimeLast && this.formData.endTimeLast) {
          this.getAccountMonth()
        }
      }

    },
    created() {
      document.title = '个人中心';
      localStorage.removeItem('loginType');
      this.getTenenants()
      this.getOrgBizUnit()
    },
    mounted() { },
    methods: {
      //切换公司
      changeCompany(guid) {
        this.getMenuList(guid);
        this.formData.tenantGuid = guid
      },
      getMenuList(tenantGuid) {
        let self = this;
        self.api
          .get('/ms-common-admin-platform-auth-service/menu/data/get-menu-by-currentuser?' + `appType=01&tenantGuid=` +
            tenantGuid + `&appSideIdentifier=QYD`)
          .then((res) => {
            if (res.data.code == '00000') {
              self.menuList = res.data.data;
              if (res.data.data[0].menuName == '主页') {
                this.getCurrentstaff(tenantGuid, res.data.data[0].path)
                // window.location = location.origin + res.data.data[0].path + "?username=" + self.$route.query
                //   .username +
                //   '&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid;
              }
            } else {
              self.$message.error(res.data.msg);
              self.loadingInstance.close();
            }
          });
      },
      getCurrentstaff(tenantGuid, path) {
        let self = this;
        self.api.get('/ms-personnel-service/staff/data/get-currentstaff?' + `tenantGuid=` + tenantGuid).then((res) => {
          if (res.data.code == '00000') {
            self.$store.commit('$_setUserId', res.data.data.userGuid);
            localStorage.setItem('userInfo', JSON.stringify(res.data.data));
            window.location.replace(location.origin + path + "?username=" + self.$route.query.username + '&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid);
          } else {
            self.$message.error(res.data.msg);
            self.loadingInstance.close();
          }
        });
      },
      //获取当前用户公司信息
      getTenenants() {
        var userGuid = localStorage.userId;
        this.api.get('/ms-personnel-service/staff-home/tenants?userGuid=' + userGuid).then((res) => {
          if (res.data.code == '00000') {
            this.companyOption = res.data.data.records;
            this.currentTenantGuid = this.$route.query.currentTenantGuid;
            this.formData.tenantGuid = this.$route.query.currentTenantGuid
            this.companyOption.forEach((item) => {
              if (item.tenantGuid == this.currentTenantGuid) {
                this.currentStaffGuid = item.staffGuid;
              }
            });
            this.getMessageList();
            this.getOrgAndProduct();
            //切换时间Tab
            this.changeTimeTab()
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      //获取消息列表
      getMessageList() {
        var menuList = JSON.parse(localStorage.menuList);
        var tenantGuid = this.currentTenantGuid;
        var currentStaffGuid = this.currentStaffGuid;
        this.api.get('/ms-sales-perform-service/msg-group/get?menuGuid=' + menuList[0].guid + '&tenantGuid=' +
          tenantGuid + '&staffGuid=' + currentStaffGuid).then((res) => {
            if (res.data.code == '00000') {
              this.messageList = res.data.data;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      //获取当前公司下的菜单
      getOrgAndProduct() {
        this.api
          .get(
            '/ms-personnel-service/staff-home/org-and-product?staffGuid=' + this.currentStaffGuid +
            '&tenantGuid=' + this.currentTenantGuid,
          )
          .then((res) => {
            if (res.data.code == '00000') {
              this.organisationName = res.data.data.organisationName;
              this.positionName = res.data.data.positionName;
              this.productsMenu = res.data.data.products;
              this.productsMenu.forEach(item => {
                if (item.icon) {
                  let obj = JSON.parse(item.icon)
                  this.$set(item, 'iconUrl', obj.path)
                }
              })
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      //获取业务单元
      getOrgBizUnit() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.api.postJson('/ms-personnel-service/organisation/data/get-org-biz-unit?orgGuid=' + userInfo.organisationGuid).then(resp => {
          if (resp.data.msg == "success") {
            let data = resp.data.data ? resp.data.data : []
            let result = []
            let rxItem = null
            data.forEach(item => {
              let obj = {
                label: item.bizUnitName.toUpperCase(),
                value: item.guid
              }
              result.push(obj)
              if (obj.label == 'RX') {
                rxItem = obj
              }
            })
            if (rxItem) {
              this.businessSelectIndex = rxItem.value
              this.pageType = rxItem.label
            } else {
              this.businessSelectIndex = result.length ? result[0].value : ''
              this.pageType = result.length ? result[0].label.toUpperCase() : ''
            }
            this.businessOption = result
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },
      //切换上月、本月、本年时间处理
      changeTimeTab() {
        this.formData.startTime = '';
        this.formData.endTime = ''
        this.formData.startTimeLast = ''
        this.formData.endTimeLast = ''
        this.newFormTime = {
          startTime: '',
          endTime: '',
          startTimeLast: '',
          endTimeLast: '',
        }
        if (this.activeTab == 'currentMonth') {  //本月
          let date = new Date()
          this.getFullAccountMonth(date)
          this.baseSearch.accountMonth = this.$moment().format('YYYYMM')
          this.baseSearch.upAccountMonth = this.$moment(date).subtract(1, "months").format("YYYYMM")
        }
        if (this.activeTab == 'lastMonth') {  //上月
          let date = new Date()
          this.getFullAccountMonth(date)
        }
        if (this.activeTab == 'currentYear') {  //本年,数据范围为本年1月的核算月开始时间至当前月的核算月结束时间
          let date = this.$moment().year(this.$moment().year()).startOf('year').valueOf()
          this.getFullAccountMonth(date, 'start')
          let endDate = new Date()
          setTimeout(() => {
            this.getFullAccountMonth(endDate, 'end')
          }, 1000);
        }
      },
      //获取完整核算月
      getFullAccountMonth(date, type) {
        let start = this.formData.startTime
        let end = this.formData.endTime
        let startLast = this.formData.startTimeLast
        let endLast = this.formData.endTimeLast
        let time = this.$moment(date).format('YYYY-MM-DD')
        this.api.get(`/ms-configure-service/sysparameter/data/get-account-month-full-area?date=${time}&tenantGuid=${this.formData.tenantGuid}`
        ).then(resp => {
          if (resp.data.msg == "success") {
            if (resp.data.data) {
              let arr = resp.data.data.split(',')
              if (arr.length) {
                if (this.activeTab == 'currentMonth') {
                  start = arr[0]
                  end = arr[1]
                  startLast = this.$moment(arr[0]).subtract(1, "months").format("YYYY-MM-DD")
                  endLast = this.$moment(arr[1]).subtract(1, "months").format("YYYY-MM-DD")
                }
                if (this.activeTab == 'lastMonth') {
                  start = this.$moment(arr[0]).subtract(1, "months").format("YYYY-MM-DD")
                  end = this.$moment(arr[1]).subtract(1, "months").format("YYYY-MM-DD")
                  startLast = this.$moment(arr[0]).subtract(2, "months").format("YYYY-MM-DD")
                  endLast = this.$moment(arr[1]).subtract(2, "months").format("YYYY-MM-DD")
                }
                if (this.activeTab == 'currentYear') {
                  if (type == 'start') {
                    start = arr[0]
                    startLast = this.$moment(arr[0]).subtract(1, "years").format("YYYY-MM-DD")
                  }
                  if (type == 'end') {
                    end = arr[1]
                    endLast = this.$moment(arr[1]).subtract(1, "years").format("YYYY-MM-DD")
                  }
                }
              }
              this.formData.startTime = start ? this.$moment(start).format("YYYYMM") : '';
              this.formData.endTime = end ? this.$moment(end).format("YYYYMM") : '';
              this.formData.startTimeLast = startLast ? this.$moment(startLast).format("YYYYMM") : '';
              this.formData.endTimeLast = endLast ? this.$moment(endLast).format("YYYYMM") : '';

              if (this.activeTab == 'currentYear') {
                this.newFormTime.startTime = this.newFormTime.startTime ? this.newFormTime.startTime : start
                this.newFormTime.endTime = this.newFormTime.endTime ? this.newFormTime.endTime : end
                this.newFormTime.startTimeLast = this.newFormTime.startTimeLast ? this.newFormTime.startTimeLast : startLast
                this.newFormTime.endTimeLast = this.newFormTime.endTimeLast ? this.newFormTime.endTimeLast : endLast
              } else {
                this.newFormTime.startTime = start
                this.newFormTime.endTime = end
                this.newFormTime.startTimeLast = startLast
                this.newFormTime.endTimeLast = endLast
              }
            }
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },
      //切换业务单元
      changeBusinessSelect() {
        this.businessOption.forEach(item => {
          if (item.value == this.businessSelectIndex) {
            this.pageType == item.label.toUpperCase()
          }
        })

        if (this.activeTab == 'currentYear') {
          this.getDataCollectionByYear()
        } else {
          this.getDataCollection()
        }
        // this.getDataCollectionChart()
        this.getGoodsOption()
        this.getStockChart()
        this.getMarketing()
        this.getMarketingChart()
      },
      //获取核算月
      getAccountMonth() {
        let time = this.$moment(this.formData.endTime).format('YYYY-MM-DD')
        this.api.get(`/ms-configure-service/sysparameter/data/get-account-month?date=${time}&tenantGuid=${this.formData.tenantGuid}`
        ).then(resp => {
          if (resp.data.msg == "success") {
            if (resp.data.data) {
              this.formData.accountMonth = resp.data.data
              this.formData.upAccountMonth = this.$moment(resp.data.data).subtract(1, "month").format("YYYYMM")
              // console.log('上个核算月',this.formData.upAccountMonth)
              if (this.activeTab == 'currentYear') {
                this.getBaseInfoByYear()
                this.getDataCollectionByYear()
              } else {
                this.getBaseInfo()
                this.getDataCollection()
              }
              this.getBaseInfoChart()
              // this.getDataCollectionChart()
              this.getGoodsOption()
              this.getStockChart()
              this.getMarketing()
              this.getMarketingChart()
            }
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },
      //基本信息
      getBaseInfo() {
        let url = '/ms-bizdata-statistics-service/Index/get-tenant-base-info'
        let params = JSON.parse(JSON.stringify(this.formData))
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        params.organisationGuid = userInfo.organisationGuid
        this.api.postJson(url, params).then(resp => {
          if (resp.data.msg == "success") {
            this.baseInfo = resp.data.data ? resp.data.data : {}
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },
      getBaseInfoByYear() {
        let url = '/ms-bizdata-statistics-service/Index/get-tenant-Tannual-base-info'
        let params = JSON.parse(JSON.stringify(this.formData))
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        params.organisationGuid = userInfo.organisationGuid
        this.api.postJson(url, params).then(resp => {
          if (resp.data.msg == "success") {
            this.baseInfo = resp.data.data ? resp.data.data : {}
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },

      //基本信息的图表
      getBaseInfoChart() {
        let url = '/ms-bizdata-statistics-service/Index/get-staff-trend-echarts'
        let data = JSON.parse(JSON.stringify(this.formData))
        data.selectIndex = this.baseInfoSelectIndex
        data.upAccountMonth = this.baseSearch.upAccountMonth
        data.accountMonth = this.baseSearch.accountMonth
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        data.organisationGuid = userInfo.organisationGuid
        this.api.postJson(url, data).then(resp => {
          if (resp.data.msg == "success") {
            this.initfirstChart(resp.data.data)
          } else {
            this.$message.error(resp.data.msg)
          }
        });


      },
      //数据采集 /Index/get-tenant-stock-data-acquisition
      getDataCollection() {
        let url = '/ms-bizdata-statistics-service/Index/get-tenant-stock-data-acquisition'
        let params = JSON.parse(JSON.stringify(this.formData))
        params.organisationGuid = this.businessSelectIndex
        this.api.postJson(url, params).then(resp => {
          if (resp.data.msg == "success") {
            this.dataAcquisition = resp.data.data ? resp.data.data : {}
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },
      getDataCollectionByYear() {
        let url = '/ms-bizdata-statistics-service/Index/get-tenant-stock-Tannual-data-acquisition'
        let params = JSON.parse(JSON.stringify(this.formData))
        params.organisationGuid = this.businessSelectIndex
        this.api.postJson(url, params).then(resp => {
          if (resp.data.msg == "success") {
            this.dataAcquisition = resp.data.data ? resp.data.data : {}
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },
      //获取数据采集图表 
      getDataCollectionChart() {
        let url = '/ms-bizdata-statistics-service/Index/get-tenant-data-acquisition-echarts'
        let data = JSON.parse(JSON.stringify(this.formData))
        data.selectIndex = this.dataAcquisitionSelectIndex
        data.organisationGuid = this.businessSelectIndex
        this.api.postJson(url, data).then(resp => {
          if (resp.data.msg == "success") {
            // this.initSecondChart(resp.data.data)
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },
      //库存情况商品下拉
      getGoodsOption() {
        let url = '/ms-bizdata-statistics-service/Index/get-tenant-goods-name-info'
        let params = JSON.parse(JSON.stringify(this.formData))
        params.organisationGuid = this.businessSelectIndex
        this.api.postJson(url, params).then(resp => {
          let data = resp.data ? resp.data : []
          let arr = []
          data.forEach(item => {
            arr.push(item.goodsName)
          })
          this.goodsOption = ['全部品种', ...arr]
        });
      },
      //商品库存情况
      getStockChart() {
        let url = '/ms-bizdata-statistics-service/Index/get-tenant-monthly-stock-echarts'
        let params = JSON.parse(JSON.stringify(this.formData))
        params.selectIndex = this.goodsSelect=='全部品种'?'全部':this.goodsSelect
        params.organisationGuid = this.businessSelectIndex
        this.loadingInstance = Loading.service({
          fullscreen: true,
          background: 'rgba(255,255,255,0.1)',
        });
        this.api.postJson(url, params).then(resp => {
            this.loadingInstance.close();
          if (resp.data.msg == "success") {
            this.initFurthChart(resp.data.data)
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },
      //市场推广
      getMarketing() {
        let url = '/ms-bizdata-statistics-service/Index/get-tenant-data-marketextension'
        let params = JSON.parse(JSON.stringify(this.formData))
        params.startTime = this.$moment(this.newFormTime.startTime).format("YYYY-MM-DD") + ' 00:00:00'
        params.startTimeLast = this.$moment(this.newFormTime.startTimeLast).format("YYYY-MM-DD") + ' 00:00:00'
        params.endTime = this.$moment(this.newFormTime.endTime).format("YYYY-MM-DD") + ' 23:59:59'
        params.endTimeLast = this.$moment(this.newFormTime.endTimeLast).format("YYYY-MM-DD") + ' 23:59:59'
        params.organisationGuid = this.businessSelectIndex
        this.api.postJson(url, params).then(resp => {
          if (resp.data.msg == "success") {
            this.marketData = resp.data.data ? resp.data.data : {}
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },
      //市场推广趋势图 
      getMarketingChart() {
        let url = '/ms-bizdata-statistics-service/Index/get-tenant-data-marketextension-echarts'
        let data = JSON.parse(JSON.stringify(this.formData))
        data.selectIndex = this.marketResearchSelectIndex
        if (data.selectIndex == 0) {
          data.startTime = this.$moment(this.newFormTime.startTime).format("YYYY-MM-DD") + ' 00:00:00'
          data.startTimeLast = this.$moment(this.newFormTime.startTimeLast).format("YYYY-MM-DD") + ' 00:00:00'
          data.endTime = this.$moment(this.newFormTime.endTime).format("YYYY-MM-DD") + ' 23:59:59'
          data.endTimeLast = this.$moment(this.newFormTime.endTimeLast).format("YYYY-MM-DD") + ' 23:59:59'
        }
        data.organisationGuid = this.businessSelectIndex
        this.api.postJson(url, data).then(resp => {
          if (resp.data.msg == "success") {
            this.initThirdChart(resp.data.data)
          } else {
            this.$message.error(resp.data.msg)
          }
        });
      },

      //初始化图表一
      initfirstChart(data) {
        let xData = []
        data.lateralAxis.forEach(item => {
          let time = new Date(item)
          xData.push(this.$moment(item).format('YYYY年M月'))
        })
        xData.reverse()
        let salesData = [] //人数
        let HBData = [] //环比
        data.datas.forEach(item => {
          if (item.name == '人数') {
            salesData = item.values
            HBData = item.rateValues
          }
        });
        salesData.reverse()
        HBData.reverse()
        let myChart = this.$echarts.init(document.getElementById('firstChart'));
        myChart.clear();
        let option = {
          grid: {
            containLabel: true,
            top: '80px',
            left: '30px',
            right: '30px',
            bottom: 0,
          },
          tooltip: {
            trigger: 'axis',
            extraCssText: 'text-align:left;',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function (params) {
              return (
                params[0].name +
                '<br>' +
                params[0].seriesName +
                '：' +
                params[0].data +
                '人<br/>' +
                params[1].seriesName +
                '：' +
                params[1].data +
                '%'
              );
            },
          },
          toolbox: {
            feature: {
              dataView: {
                show: false,
                readOnly: false,
              },
              magicType: {
                show: false,
                type: ['line', 'bar'],
              },
              restore: {
                show: false,
              },
              saveAsImage: {
                show: false,
              },
            },
          },
          legend: {
            data: ['人数', '环比'],
            right: '20px',
            selectedMode: false,
            top: '25px',
          },
          xAxis: [{
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          },],
          yAxis: [{
            type: 'value',
            name: '单位：人',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          {
            type: 'value',
            name: '单位：%',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          ],
          series: [{
            name: '人数',
            barMaxWidth: '35px',
            type: 'bar',
            data: salesData,
            label: {
              show: true,
              color: '#000000',
              position: 'top'
            },
            itemStyle: {
              normal: {
                color: '#A3D9D3',
              },
              borderRadius: [6, 6, 0, 0],
            },
          },
          {
            name: '环比',
            barMaxWidth: '35px',
            type: 'line',
            yAxisIndex: 1,
            // smooth: true,
            data: HBData,
            label: {
              show: true,
              color: '#000000',
              position: 'top'
            },
            itemStyle: {
              normal: {
                color: '#4f54c8',
              },
              borderRadius: [4, 4, 0, 0],
            },
            showSymbol: false,
            areaStyle: {
              opacity: 1,
              color: new newEcharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(80, 85, 201, 0.2)',
              },
              {
                offset: 0.5,
                color: 'rgba(255, 255, 255, 0.1)',
              },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
          },
          ],
        };
        myChart.setOption(option);
        window.addEventListener('resize', function () {
          myChart.resize();
        });
      },
      //初始化图表二
      initSecondChart(data) {
        let xData = []
        let tipTitle = '家数'
        data.lateralAxis.forEach(item => {
          let time = new Date(item)
          xData.push(this.$moment(item).format('YYYY年M月'))
        })

        // data.datas.forEach(item => {
        //   if (item.name == '家数') {
        //     salesData = item.values
        //   }
        //   if (item.name == '环比') {
        //     HBData = item.rateValues
        //   }
        // });
        let obj1 = data.datas.length ? data.datas[0] : {}
        tipTitle = obj1.name
        let salesData = obj1.values //人数
        let HBData = obj1.rateValues //环比
        let myChart = this.$echarts.init(document.getElementById('secondChart'));
        myChart.clear();
        let option = {
          grid: {
            containLabel: true,
            top: '80px',
            left: '30px',
            right: '30px',
            bottom: 0,
          },
          tooltip: {
            trigger: 'axis',
            extraCssText: 'text-align:left;',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function (params) {
              return (
                params[0].name +
                '<br>' +
                params[0].seriesName +
                '：' +
                params[0].data +
                '家<br/>' +
                params[1].seriesName +
                '：' +
                params[1].data +
                '%'
              );
            },
          },
          toolbox: {
            feature: {
              dataView: {
                show: false,
                readOnly: false,
              },
              magicType: {
                show: false,
                type: ['line', 'bar'],
              },
              restore: {
                show: false,
              },
              saveAsImage: {
                show: false,
              },
            },
          },
          legend: {
            data: [tipTitle, '环比'],
            right: '20px',
            selectedMode: false,
            top: '25px',
          },
          xAxis: [{
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          },],
          yAxis: [{
            type: 'value',
            name: '单位：家',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          {
            type: 'value',
            name: '单位：%',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          ],
          series: [{
            name: tipTitle,
            barMaxWidth: '35px',
            type: 'bar',
            data: salesData,
            label: {
              show: true,
              color: '#000000',
              position: 'top'
            },
            itemStyle: {
              normal: {
                color: '#A3D9D3',
              },
              borderRadius: [6, 6, 0, 0],
            },
          },
          {
            name: '环比',
            barMaxWidth: '35px',
            type: 'line',
            yAxisIndex: 1,
            // smooth: true,
            data: HBData,
            label: {
              show: true,
              color: '#000000',
              position: 'top'
            },
            itemStyle: {
              normal: {
                color: '#4f54c8',
              },
              borderRadius: [4, 4, 0, 0],
            },
            showSymbol: false,
            areaStyle: {
              opacity: 1,
              color: new newEcharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(80, 85, 201, 0.2)',
              },
              {
                offset: 0.5,
                color: 'rgba(255, 255, 255, 0.1)',
              },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
          },
          ],
        };
        myChart.setOption(option);
        window.addEventListener('resize', function () {
          myChart.resize();
        });
      },
      //初始化图表三
      initThirdChart(data) {
        let xData = []
        let tipTitle = '家数'
        data.lateralAxis.forEach(item => {
          let time = new Date(item)
          xData.push(this.$moment(item).format('YYYY年M月'))
        })
        let obj1 = data.datas.length ? data.datas[0] : {}
        tipTitle = obj1.name
        let salesData = obj1.values //人数
        let HBData = obj1.rateValues //环比
        xData.reverse();
        salesData.reverse();
        HBData.reverse();
        let myChart = this.$echarts.init(document.getElementById('thirdChart'));
        myChart.clear();
        let option = {
          grid: {
            containLabel: true,
            top: '80px',
            left: '30px',
            right: '30px',
            bottom: 0,
          },
          tooltip: {
            trigger: 'axis',
            extraCssText: 'text-align:left;',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function (params) {
              return (
                params[0].name +
                '<br>' +
                params[0].seriesName +
                '：' +
                params[0].data +
                '家<br/>' +
                params[1].seriesName +
                '：' +
                params[1].data +
                '%'
              );
            },
          },
          toolbox: {
            feature: {
              dataView: {
                show: false,
                readOnly: false,
              },
              magicType: {
                show: false,
                type: ['line', 'bar'],
              },
              restore: {
                show: false,
              },
              saveAsImage: {
                show: false,
              },
            },
          },
          legend: {
            data: [tipTitle, '环比'],
            right: '20px',
            selectedMode: false,
            top: '25px',
          },
          xAxis: [{
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          },],
          yAxis: [{
            type: 'value',
            name: '单位：家',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          {
            type: 'value',
            name: '单位：%',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          ],
          series: [{
            name: tipTitle,
            barMaxWidth: '35px',
            type: 'bar',
            data: salesData,
            label: {
              show: true,
              color: '#000000',
              position: 'top'
            },
            itemStyle: {
              normal: {
                color: '#A3D9D3',
              },
              borderRadius: [6, 6, 0, 0],
            },
          },
          {
            name: '环比',
            barMaxWidth: '35px',
            type: 'line',
            yAxisIndex: 1,
            // smooth: true,
            data: HBData,
            label: {
              show: true,
              color: '#000000',
              position: 'top'
            },
            itemStyle: {
              normal: {
                color: '#4f54c8',
              },
              borderRadius: [4, 4, 0, 0],
            },
            showSymbol: false,
            areaStyle: {
              opacity: 1,
              color: new newEcharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(80, 85, 201, 0.2)',
              },
              {
                offset: 0.5,
                color: 'rgba(255, 255, 255, 0.1)',
              },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
          },
          ],
        };
        myChart.setOption(option);
        window.addEventListener('resize', function () {
          myChart.resize();
        });
      },
      //初始化图表三
      initFurthChart(data) {
        let xData = []
        let tipTitle = '盒'
        data.lateralAxis.forEach(item => {
          let time = new Date(item)
          xData.push(this.$moment(item).format('YYYY年M月'))
        })
        let obj1 = data.datas.length ? data.datas[0] : {}
        tipTitle = obj1.name
        let salesData = obj1.values //人数
        let HBData = obj1.rateValues //环比
        xData.reverse()
        salesData.reverse()
        HBData.reverse()
        let myChart = this.$echarts.init(document.getElementById('furthChart'));
        myChart.clear();
        let option = {
          grid: {
            containLabel: true,
            top: '80px',
            left: '30px',
            right: '30px',
            bottom: 0,
          },
          tooltip: {
            trigger: 'axis',
            extraCssText: 'text-align:left;',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function (params) {
              return (
                params[0].name +
                '<br>' +
                params[0].seriesName +
                '：' +
                params[0].data +
                '盒<br/>' +
                params[1].seriesName +
                '：' +
                params[1].data +
                '%'
              );
            },
          },
          toolbox: {
            feature: {
              dataView: {
                show: false,
                readOnly: false,
              },
              magicType: {
                show: false,
                type: ['line', 'bar'],
              },
              restore: {
                show: false,
              },
              saveAsImage: {
                show: false,
              },
            },
          },
          legend: {
            data: [tipTitle, '环比'],
            right: '20px',
            selectedMode: false,
            top: '25px',
          },
          xAxis: [{
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          },],
          yAxis: [{
            type: 'value',
            name: '单位：盒',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          {
            type: 'value',
            name: '单位：%',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          ],
          series: [{
            name: tipTitle,
            barMaxWidth: '35px',
            type: 'bar',
            data: salesData,
            label: {
              show: true,
              color: '#000000',
              position: 'top'
            },
            itemStyle: {
              normal: {
                color: '#A3D9D3',
              },
              borderRadius: [6, 6, 0, 0],
            },
          },
          {
            name: '环比',
            barMaxWidth: '35px',
            type: 'line',
            yAxisIndex: 1,
            // smooth: true,
            data: HBData,
            label: {
              show: true,
              color: '#000000',
              position: 'top'
            },
            itemStyle: {
              normal: {
                color: '#4f54c8',
              },
              borderRadius: [4, 4, 0, 0],
            },
            showSymbol: false,
            areaStyle: {
              opacity: 1,
              color: new newEcharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(80, 85, 201, 0.2)',
              },
              {
                offset: 0.5,
                color: 'rgba(255, 255, 255, 0.1)',
              },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
          },
          ],
        };
        myChart.setOption(option);
        window.addEventListener('resize', function () {
          myChart.resize();
        });
      },
    }
  }
</script>